import { useTimeAgo } from "@vueuse/core";

export default function useTranslatedTimeAgo (...params: Parameters<typeof useTimeAgo>): ReturnType<typeof useTimeAgo> {
  params[1] = {
    ...params[1],
    ...{
      fullDateFormatter: (date: Date) => date.toLocaleString(),
      messages: {
        justNow: "à l'instant",
        past: n => n.match(/\d/) ? `il y a ${n}` : n,
        future: n => n.match(/\d/) ? `dans ${n}` : n,
        month: (n, past) => n === 1
          ? past
            ? "le mois dernier"
            : "le mois prochain"
          : "mois",
        year: (n, past) => n === 1
          ? past
            ? "l'année dernière"
            : "l'année prochaine"
          : `${n} année${n > 1 ? "s" : ""}`,
        day: (n, past) => n === 1
          ? past
            ? "hier"
            : "demain"
          : `${n} jour${n > 1 ? "s" : ""}`,
        week: (n, past) => n === 1
          ? past
            ? "la semaine dernière"
            : "le mois dernier"
          : `${n} semaine${n > 1 ? "s" : ""}`,
        hour: n => `${n} heure${n > 1 ? "s" : ""}`,
        minute: n => `${n} minute${n > 1 ? "s" : ""}`,
        second: n => `${n} seconde${n > 1 ? "s" : ""}`
      }
    }
  } as any;

  return useTimeAgo(...params);
}
